import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import { observer } from 'mobx-react';

import * as ROUTES from '../constants/routes';
import { withAuthentication } from '../services/Session';
import { UserContext } from '../services/Session/context';
import BetaInfoPage from '../features/StaticRedirects/BetaInfoPage';
import { ClaimUserPage } from '../features/SignIn/SignIn';
import { StreamingInfoPage } from '../features/StaticRedirects/StaticRedirects';
import TopPlayers from '../features/TopPlayers/TopPlayers';
import TeamsHitScope from '../features/HitScope/TeamsHitScope';
import GameHitScope from '../features/HitScope/GameHitScope';
import HitScope from '../features/HitScope/HitScope';
import StreamingConfig from '../features/StreamingConfig/StreamingConfig';
import Settings from '../features/Settings/Settings';
import Onboarding from '../features/Onboarding/Onboarding';
import ScoringScreen from '../features/Scoring/ScoringScreen';
import VBScoringScreen from '../features/Scoring/VBScoringScreen';
import SetLineups from '../features/Scoring/SetLineups';
import ScheduleGame from '../features/Scoring/ScheduleGame';
import ScoringConfig from '../features/ScoringConfig/ScoringConfig';
import CompilationPage from '../features/CompilationPage/CompilationPage';
import HighlightPage from '../features/HighlightPage/HighlightPage';
import ClaimPlayerPage from '../features/ClaimPlayerPage/ClaimPlayerPage';
import CoachPage from '../features/Coach/Coach';
import TeamSearch from '../features/TeamSearch/TeamSearch';
import GameChanger from '../features/SignUp/GameChanger';
// import GetStarted from '../features/SignUp/GetStarted';
import IFrameEmbed from './IFrameEmbed';
import Handle from './Handle';
import GameSwitch from '../features/Game/Game';
import EnableLivestreamer from '../features/Onboarding/EnableLivestreamer';
// import GetStartedVB from '../features/SignUp/GetStartedVB';
import VBSetLineups from '../features/Scoring/VBSetLineups';
import GenericSetLineups from '../features/Scoring/GenericSetLineups';
import GenericScoringScreen from '../features/Scoring/GenericScoringScreen';
// import GetStartedGeneric from '../features/SignUp/GetStartedGeneric';
import Playlist from '../features/Playlist/Playlist';
import VideoPlaylist from '../features/VideoPlaylist/VideoPlaylist';
import ClaimPlayer from '../features/Player/ClaimPlayer';
import PartnerReferral from '../features/SignUp/PartnerReferral';
import StopImpersonation from '../features/Admin/StopImpersonation';
import MLBScoresOverlay from './MLBScoresOverlay';
import HitMatch from '../features/Player/HitMatch';
import TeamSnapCallback from '../features/Onboarding/TeamSnapCallback';
import SidelineCam from '../features/SidelineCam/SidelineCam';
import RemoteLoginAuthorize from '../features/RemoteLogin/RemoteLoginAuthorize';
import AppGoliveTeam from '../features/AppGolive/AppGoliveTeam';
import {
  AdminStreamboard,
  ShdproStreamboard,
} from '../features/Admin/StreamBoard';
import { Search } from 'history';
import StreamLog from '../features/Admin/StreamLog';
import Home from '../features/TeamPage/Home';
import TeamSettings from '../features/TeamPage/TeamSettings';
import Auth0Login from '../features/SignIn/Auth0Login';
import FirebaseTokenExchange from '../features/SignIn/FirebaseTokenExchange';
import { Livestream } from '../features/Livestream/Livestream';

import DKPlayerRecommendations from '../features/DK/DKPlayerRecommendations';
import { AppHostContext } from '../services/appHost';
import AuthorizeYoutubeTeam from '../features/AuthorizeYoutube/AuthorizeYoutubeTeam';
import SidelineLive from '../features/SidelineLive/SidelineLive';
import SidelineRedirect from './SidelineRedirect';
import SignInContainer from '../features/SignIn/SignInContainer';
import SignInV2 from '../features/SignIn/SignInV2';
import InvitedUserContainer from '../features/SignIn/InvitedUserContainer';

// Admin
import Admin from '../features/Admin/Admin';
import AdminMergePlayers from '../features/Admin/MergePlayers/MergePlayers';
import AdminPlayerPage from '../features/Admin/AdminPlayerPage';
import AdminRelabelClips from '../features/Admin/RelabelClips/RelabelClips';
import AdminSearch from '../features/Admin/Search/Search';
import AdminTeamPage from '../features/Admin/AdminTeamPage';
import AdminUserPage from '../features/Admin/AdminUserPage';
import SingleClipPage from '../features/Player/SingleClipPage';

import SettingsManageSubscription from '../features/Settings/SettingsManageSubscription';

const LoggedInRoute: React.FC<React.PropsWithChildren<RouteProps>> = ({
  children,
  ...rest
}) => {
  const userStore = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        userStore.authUser ? (
          React.cloneElement(children as React.ReactElement, props)
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.SIGN_IN,
              search: `?redirectUrl=${props.location.pathname.slice(1)}${
                location.search
              }`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Landing = () => {
  window.location.href = 'https://home.sidelinehd.com';
  return null;
};

const LoggedOutRoute: React.FC<
  React.PropsWithChildren<RouteProps & { search?: Search }>
> = observer(({ children, search, ...rest }) => {
  const userStore = useContext(UserContext);

  let redirectPath: string | null = '';

  const query = new URLSearchParams(rest.location?.search);
  if (userStore.activeOnboardingTeam) {
    redirectPath = ROUTES.ONBOARDING;
  } else if (query.get('redirectUrl')) {
    redirectPath = query.get('redirectUrl');
  } else if (userStore.selectedTeam) {
    redirectPath = `/${userStore.selectedTeam.nameHandle}`;
  } else if (userStore.selectedPlayer) {
    redirectPath = `/${userStore.selectedPlayer.nameHandle}`;
  }

  // TODO: figure out why this was here
  // else if (userStore.isPermittedToCreateTeam && userStore.isReturned) {
  //   redirectPath = ROUTES.ONBOARDING;
  // }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !userStore.authUser ? (
          children
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {redirectPath ? (
              <Redirect
                to={{
                  pathname: redirectPath,
                  state: { from: location },
                  search,
                }}
              />
            ) : (
              children
            )}
          </>
        )
      }
    />
  );
});

const SpecialHomeRoute: React.FC<{ path: string }> = ({ path }) => {
  const appHost = useContext(AppHostContext);
  const userStore = useContext(UserContext);

  let redirectPath = '';
  if (userStore.activeOnboardingTeam) {
    redirectPath = ROUTES.ONBOARDING;
  } else if (
    userStore.selectedTeam ||
    userStore.selectedPlayer ||
    userStore.coachTeamIds.length > 0
  ) {
    redirectPath = ROUTES.HOME;
  } else if (userStore.isPermittedToCreateTeam && userStore.isReturned) {
    redirectPath = ROUTES.ONBOARDING;
  }

  if (appHost.isHosted) {
    if (appHost.initState === 'store') {
      if (!redirectPath) {
        redirectPath = ROUTES.HOME;
      }

      return (
        <Route
          exact
          path={path}
          component={() => <Redirect to={redirectPath} />}
        />
      );
    } else {
      return <Route exact path={path} component={() => null} />;
    }
  } else {
    return (
      <Route
        exact
        path={path}
        // eslint-disable-next-line react/no-unstable-nested-components
        component={() => {
          if (redirectPath) {
            return <Redirect to={redirectPath} />;
          }
          if (process.env.REACT_APP_FIREBASE_CONFIG !== 'production') {
            return <Redirect to={ROUTES.SIGN_IN} />;
          }
          return <Landing />;
        }}
      />
    );
  }
};

const App = () => {
  return (
    <Switch>
      <LoggedOutRoute path={ROUTES.BETA_INFO}>
        <BetaInfoPage />
      </LoggedOutRoute>
      <LoggedOutRoute exact path={ROUTES.CLAIM_USER}>
        <ClaimUserPage />
      </LoggedOutRoute>
      <Route path={ROUTES.INVITED_USER} component={InvitedUserContainer} />
      <Route path={ROUTES.GAME} component={GameSwitch} />
      <Route path={ROUTES.STREAMING_INFO} component={StreamingInfoPage} />
      <Route path={ROUTES.TOP_PLAYERS} component={TopPlayers} />
      <Route path={ROUTES.TEAMS_HITSCOPE} component={TeamsHitScope} />
      <Route path={ROUTES.GAME_HITSCOPE} component={GameHitScope} />
      <Route path={ROUTES.HITSCOPE} component={HitScope} />
      <LoggedInRoute path={ROUTES.STREAMING_CONFIG}>
        <StreamingConfig />
      </LoggedInRoute>

      <LoggedInRoute path={ROUTES.TEAM_SETTINGS}>
        <TeamSettings />
      </LoggedInRoute>

      <LoggedInRoute path={ROUTES.SIDELINE_CAM}>
        <SidelineCam />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.REMOTE_LOGIN_AUTHORIZE}>
        <RemoteLoginAuthorize />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.APP_GOLIVE_TEAM}>
        <AppGoliveTeam />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.LIVESTREAM}>
        <Livestream />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.SETTINGS} exact>
        <Settings />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.SETTINGS__SUBSCRIPTION} exact>
        <SettingsManageSubscription />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.ENABLE_LIVESTREAMER}>
        <EnableLivestreamer />
      </LoggedInRoute>

      {/* Admin */}

      <LoggedInRoute path={ROUTES.ADMIN} exact>
        <Admin />
      </LoggedInRoute>

      <LoggedInRoute path={ROUTES.ADMIN_MERGE_PLAYERS} exact>
        <AdminMergePlayers />
      </LoggedInRoute>

      <LoggedInRoute path={ROUTES.ADMIN_RELABEL_CLIPS} exact>
        <AdminRelabelClips />
      </LoggedInRoute>

      <LoggedInRoute path={ROUTES.ADMIN_SEARCH} exact>
        <AdminSearch />
      </LoggedInRoute>

      <LoggedInRoute path="/admin/streamboard" exact>
        <ShdproStreamboard />
      </LoggedInRoute>

      <LoggedInRoute path={ROUTES.ADMIN_STREAMBOARD}>
        <AdminStreamboard />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.PRO_STREAMBOARD}>
        <ShdproStreamboard />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.ADMIN_STREAMLOG}>
        <StreamLog />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.ADMIN_TEAM}>
        <AdminTeamPage />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.ADMIN_PLAYER}>
        <AdminPlayerPage />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.ADMIN_USER}>
        <AdminUserPage />
      </LoggedInRoute>
      <LoggedInRoute path={ROUTES.STOP_IMPERSONATION}>
        <StopImpersonation />
      </LoggedInRoute>

      <LoggedOutRoute path={ROUTES.AUTH0_FIREBASE_CALLBACK}>
        <FirebaseTokenExchange />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.AUTH0_LOGIN}>
        <Auth0Login />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.SIGN_IN}>
        <SignInContainer />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.PHONE_SIGN_IN}>
        <SignInV2 />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.SIGN_UP}>
        <SignInContainer />
      </LoggedOutRoute>
      {/* <Route path={ROUTES.SIGN_IN} component={SignInPage} /> */}
      <LoggedInRoute path={ROUTES.ONBOARDING}>
        <Onboarding />
      </LoggedInRoute>
      <Route
        path="/scoring/bbsb/score/:teamId/:gameId"
        component={ScoringScreen}
      />
      <Route
        path="/scoring/vb/score/:teamId/:gameId"
        component={VBScoringScreen}
      />
      <Route
        path="/scoring/generic/score/:teamId/:gameId"
        component={GenericScoringScreen}
      />
      <Route path="/scoring/lineups/:teamId/:gameId" component={SetLineups} />
      <Route
        path="/scoring/vb_lineups/:teamId/:gameId"
        component={VBSetLineups}
      />
      <Route
        path="/scoring/lineups_v2/:teamId/:gameId"
        component={GenericSetLineups}
      />
      <Route path="/scoring/schedule" component={ScheduleGame} />
      <Route path={ROUTES.SCORING} component={ScoringConfig} />
      <Route path={ROUTES.COMPILATION} component={CompilationPage} />
      <Route path={ROUTES.HIGHLIGHT} component={HighlightPage} />
      <Route path={ROUTES.HITMATCH}>
        <HitMatch />
      </Route>
      <Route path={ROUTES.CLAIM_PLAYER} component={ClaimPlayerPage} />
      <Route path={ROUTES.COACH} component={CoachPage} />
      <Route path={ROUTES.TEAM_SEARCH} component={TeamSearch} />
      <Route path={ROUTES.CLAIM_PLAYER_HOME} component={ClaimPlayer} />
      <Route path={ROUTES.TEAMSNAP_CALLBACK} component={TeamSnapCallback} />
      <Route path={ROUTES.GAMECHANGER_IMPORT}>
        <GameChanger />
      </Route>
      <LoggedOutRoute path={ROUTES.GET_STARTED}>
        {/* <GetStarted /> */}
        <SignInContainer />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.GET_STARTED_VB}>
        {/* <GetStartedVB /> */}
        <SignInContainer />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.GET_STARTED_GENERIC}>
        {/* <GetStartedGeneric /> */}
        <SignInContainer />
      </LoggedOutRoute>
      <LoggedOutRoute path={ROUTES.PARTNER_REFERRAL}>
        <PartnerReferral />
      </LoggedOutRoute>
      <Route path={ROUTES.MLB_SCORES_OVERLAY}>
        <MLBScoresOverlay />
      </Route>
      <Route path={ROUTES.PLAYLIST} component={Playlist} />
      <Route path={ROUTES.VIDEO_PLAYLIST} component={VideoPlaylist} />
      <Route exact path={ROUTES.PRIVACY_POLICY}>
        <IFrameEmbed src="https://docs.google.com/document/d/e/2PACX-1vT0kaLCudrbzskBknxIfhC7UM_0F7Bqlaeg1YaAxZ26DE7MorGvIPX28vPbC1YgyPa29VlIUPwftkLU/pub?embedded=true" />
      </Route>
      <Route exact path={ROUTES.TERMS_OF_SERVICE}>
        <IFrameEmbed src="https://docs.google.com/document/d/e/2PACX-1vRBN64Sr8x-8JLQWzd4ALlJyG0V_t1OCWHwdb32rMoqO_D8eFnRoUzj9qjWraJvmQ8hDOsieGaZ_9ys/pub?embedded=true" />
      </Route>
      <Route exact path={ROUTES.EULA}>
        <IFrameEmbed src="https://docs.google.com/document/d/e/2PACX-1vTMQfJWXEAL5Z5cAYx0wjChjUN8uaruc7RcAwo1TlKdiH0-AjOM-vTwcFNHkck408_bGwyC9AxP-bdx/pub?embedded=true" />
      </Route>
      <Route exact path={ROUTES.ATTRIBUTIONS}>
        <IFrameEmbed src="https://docs.google.com/document/d/1Ka5vrz5gSRVxq0DXmWKVeROGSeNH5AYGUTsp22k-Uoo/pub?embedded=true" />
      </Route>
      {/* <SpecialHomeRoute path={ROUTES.HOME} /> */}
      <Route path={ROUTES.HOME} component={Home} />
      <Route exact path="/:handle" component={Handle} />
      {/* <Route path="/:handle/c/:clipId?" component={Handle} /> */}
      {/* <Route path="/:handle/c/:clipId?" component={SingleClipPage} /> */}
      <Route
        path="/:handle/c/:clipId"
        render={({ match }) => (
          <SingleClipPage
            handle={match.params.handle}
            clipId={match.params.clipId}
          />
        )}
      />
      <Route
        path={ROUTES.DK_PLAYER_RECOMMENDATIONS}
        component={DKPlayerRecommendations}
      />
      <Route path={ROUTES.AUTHORIZE_YOUTUBE} component={AuthorizeYoutubeTeam} />

      <Route path={ROUTES.SIDELINE_LIVE} component={SidelineLive} />
      <Route path={ROUTES.SIDELINE_LIVE_HANDLE} component={SidelineLive} />

      <Route path={ROUTES.SIDELINE_LIVE_OG} component={SidelineRedirect} />
      <SpecialHomeRoute path="/" />
    </Switch>
  );
};

export default withAuthentication(App);
