import { Box, Image, Layer, ResponsiveContext, Text, Tab, Tabs } from 'grommet';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import Sticky from 'react-stickynode';
import {
  Link,
  RouteComponentProps,
  match,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { compose } from 'recompose';
import { LinkPrevious } from 'grommet-icons';

import { withFirebase } from '../../services/Firebase';
import { UserContext } from '../../services/Session/context';

import { HOME } from '../../constants/routes';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';

import { BBSB_SPORTS } from '../../constants/strings';
import Games from './Games';
import Highlights from './Highlights';
import Roster from './Roster';
import QrModal from '../../helpers/QrModal';

import YoutubeButton from '../../helpers/YoutubeButton';
import FacebookButton from '../../helpers/FacebookButton';
import withLayout from '../../helpers/withLayout';
import TeamLiveCardDesktop from './LiveCard/TeamLiveCardDesktop';
import { shd } from '@shd/jslib/models';
import axios from '../../helpers/axios';
import * as Sentry from '@sentry/browser';
import GoLiveBar from '../GoLive/GoLiveBar';
import TeamsHitScope from '../HitScope/TeamsHitScope';
import { analytics } from '../../services/analytics';
import { useDebounce } from '../../helpers/useDebounce';
import { reaction } from 'mobx';
import useZendeskWidget from '../../helpers/useZendeskWidget';
import ClaimRequests from './ClaimRequests';
import {
  getUserTypeRelativeToTeam,
  shouldPromptForAccountInfo,
} from '../../helpers/utils';
import {
  LiveEvents,
  LiveEventPayload,
} from '../SidelineLive/sidelineLive.analytics';

import TeamInfo from './TeamInfo';
import TeamActionButtons from './TeamActionButtons';
import LiveCard from './LiveCard';
import AccountInfo from '../Onboarding/AccountInfo';
interface DesktopTeamHeaderProps {
  team: shd.Team;
  isAdmin: boolean;
  setShowQrModal: (show: boolean) => void;
  screenSize: string;
}

const DesktopTeamHeader: React.FC<DesktopTeamHeaderProps> = ({
  team,
  isAdmin,
  setShowQrModal,
  screenSize,
}) => {
  return (
    <Box direction="row" gap="medium" fill="horizontal" justify="between">
      <Box direction="row" gap="medium">
        <TeamInfo team={team} />
        <Box
          key="stream-destination-buttons"
          justify="start"
          direction="row"
          gap="small"
        >
          <YoutubeButton teamId={team?._id} isAdmin={isAdmin} />
          <FacebookButton teamId={team?._id} isAdmin={isAdmin} />
        </Box>
      </Box>
      <TeamActionButtons
        isAdmin={isAdmin}
        setShowQrModal={setShowQrModal}
        screenSize={screenSize}
      />
    </Box>
  );
};

interface MobileTeamHeaderProps {
  team: shd.Team;
  isAdmin: boolean;
  trackEvent: (event: LiveEvents, payload: LiveEventPayload) => void;
}
const MobileTeamHeader: React.FC<MobileTeamHeaderProps> = ({
  team,
  isAdmin,
  trackEvent,
}) => {
  return (
    <Box pad="medium" gap="medium" fill="horizontal">
      <TeamInfo team={team} />
      <Box
        key="stream-destination-buttons"
        justify="start"
        direction="row"
        gap="small"
      >
        <YoutubeButton teamId={team?._id} isAdmin={isAdmin} />
        <FacebookButton teamId={team?._id} isAdmin={isAdmin} />
      </Box>
      <LiveCard
        teamId={team._id}
        teamName={team.nameMed}
        trackEvent={trackEvent}
      />
    </Box>
  );
};

interface Props extends RouteComponentProps {
  teamId: string;
  match: match<{ handle: string }>;
  fromInvite?: string;
}
const TeamPage: React.FC<Props> = ({
  teamId,
  history,
  match: routeMatch,
  fromInvite = undefined,
}) => {
  const [tabs, setTabs] = useState([
    'Highlights',
    'Games',
    'HitScope',
    'Roster',
  ]);

  const location = useLocation();

  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);

  const [tabIndex, setTabIndex] = useState(0);
  const [showQrModal, setShowQrModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [goLiveBarOpacity, setGoLiveBarOpacity] = useState(1);

  const [teamData, setTeamData] = useState<shd.Team | null>(null);

  const [showAccountInfoModal, setShowAccountInfoModal] =
    useState<boolean>(false);

  const teamIds = userStore.userTeams.map((userTeam) => userTeam._id);
  const isAdmin = teamIds.includes(teamId);

  const team =
    userStore.authUser && isAdmin ? userStore.selectedTeam : teamData;

  const trackLiveEvent = useCallback(
    (event: LiveEvents, payload: LiveEventPayload) => {
      analytics.track(event, {
        ...payload,
        signedIn: !!userStore.authUser,
        userType: getUserTypeRelativeToTeam(teamId, userStore),
        userId: userStore.authUser?.claims?.shd_user_id,
        teamId: team?._id,
        source: 'sidelineLIVE',
        sport: team?.attrib_sportType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchTeam = useCallback(
    async (auth = false) => {
      await axios
        .get(`/api/team/${teamId}`)
        .then((res) => {
          setTeamData(res.data.info);
          if (auth) {
            userStore.setSelectedTeam(res.data.info);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          setLoading(false);
        });
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useZendeskWidget();

  useEffect(() => {
    if (!isAdmin) {
      setLoading(true);
      fetchTeam();
    }
  }, [teamId, isAdmin, fetchTeam]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get('tab'); // Get the 'tab' query parameter
    if (!tabParam) {
      return;
    }

    const _tabIndex = tabs
      .map((tab) => tab.toLowerCase())
      .indexOf(tabParam.toLowerCase());

    if (_tabIndex !== -1) {
      setTabIndex(_tabIndex);
    }
  }, [tabs, location]);

  useEffect(() => {
    if (userStore.authUser && teamIds.includes(teamId)) {
      fetchTeam(true);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTeam, userStore.authUser]);

  useEffect(() => {
    if (team) {
      setLoading(false);

      if (!BBSB_SPORTS.includes(team?.attrib_sportType)) {
        setTabs(['Games', 'Roster']);
      }
      const urlPath = routeMatch.params.handle;
      if (urlPath.includes('TmSh01-')) {
        history.replace({ pathname: `/${team.nameHandle}` });
      }
    }
  }, [history, routeMatch.params.handle, team]);

  useEffect(() => {
    const handleScroll = () => {
      const startDimmingY = 200;
      const fullyDimmedY = 400;
      const fullyDimmedOpacity = 0.3;

      if (window.scrollY > startDimmingY) {
        setGoLiveBarOpacity(
          Math.max(
            fullyDimmedOpacity,
            1 -
              (window.scrollY - startDimmingY) / (fullyDimmedY - startDimmingY)
          )
        );
      } else {
        setGoLiveBarOpacity(1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const debouncedTrackTeamPageView = useDebounce((t: shd.Team) => {
    analytics.track('Team Page View', {
      isAge13Plus: t.isAge13Plus,
      sport: t.attrib_sportType,
      userType: getUserTypeRelativeToTeam(teamId, userStore),
      teamId: t._id,
      tab: tabs[tabIndex],
    });
  }, 5000);

  useEffect(() => {
    // hack: userStore is auto observable so this component is re-rendering a lot.
    // only react to selectedTeam and aggressively debounce
    const dispose = reaction(
      () => ({ selectedTeam: userStore.selectedTeam }),
      ({ selectedTeam }) => {
        if (selectedTeam) {
          debouncedTrackTeamPageView(selectedTeam);
        }
      }
    );
    return () => dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTrackTeamPageView]);

  useEffect(() => {
    // hack: track team page view differently for logged out users
    if (team && !userStore.authUser) {
      debouncedTrackTeamPageView(team);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, debouncedTrackTeamPageView]);

  useEffect(() => {
    const shouldPrompt = shouldPromptForAccountInfo(
      userStore.user?.nameFirst,
      userStore.user?.nameLast
    );
    if (shouldPrompt) {
      setShowAccountInfoModal(true);
    }
  }, [userStore, userStore.authUser, userStore.user, fromInvite]);

  const onActive = (index: number) => {
    // TODO: It's ok if we don't update the URL query parameter
    // for different tabs for now. We will add this later.
    const tabName = tabs[index].toLowerCase();
    if (userStore.authUser && isAdmin) {
      history.replace(`${location.pathname}?tab=${tabName}`);
    }
    setTabIndex(index);
  };

  let teamPhotoSize = '120px';
  let marginTop = -48;
  let teamPhotoPaddingHorizontal = '0px';
  if (screenSize === 'small') {
    teamPhotoSize = '80px';
    marginTop = -48;
    teamPhotoPaddingHorizontal = 'medium';
  }

  if (!team) {
    return null;
  }

  return (
    <Box fill>
      {team && isAdmin && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 'env(safe-area-inset-bottom, 16px)',
            left: 0,
            right: 0,
            zIndex: 10,
            opacity: goLiveBarOpacity,
          }}
        >
          <GoLiveBar team={team} />
        </Box>
      )}
      {isAdmin && <ClaimRequests teamId={teamId} />}
      {screenSize === 'large' && (
        <TeamLiveCardDesktop teamId={teamId} sport={team.attrib_sportType} />
      )}
      <ResponsiveGrid
        fill="horizontal"
        responsive
        rows={['auto', 'flex']}
        columns={['10%', 'auto', '10%']}
        areas={{
          small: [
            { name: 'header', start: [0, 0], end: [2, 0] },
            { name: 'main', start: [0, 1], end: [2, 1] },
          ],
          medium: [
            // { name: 'header-left', start: [0, 0], end: [0, 0] },
            { name: 'header', start: [0, 0], end: [2, 0] },
            // { name: 'header-right', start: [2, 0], end: [2, 0] },
            // { name: 'main-left', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [0, 1], end: [2, 1] },
            // { name: 'main-right', start: [2, 1], end: [2, 1] },
          ],
          large: [
            { name: 'header-left', start: [0, 0], end: [0, 0] },
            { name: 'header', start: [1, 0], end: [1, 0] },
            { name: 'header-right', start: [2, 0], end: [2, 0] },
            { name: 'main-left', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [1, 1] },
            { name: 'main-right', start: [2, 1], end: [2, 1] },
          ],
        }}
      >
        <Box
          gridArea="header-left"
          background={'white'}
          border={{ side: 'bottom', color: 'light-1', size: '1px' }}
        ></Box>
        <Box
          gridArea="header"
          border={{ side: 'bottom', color: 'light-1', size: '1px' }}
        >
          <Box
            style={{ position: 'relative' }}
            skeleton={loading}
            margin={{ horizontal: screenSize === 'medium' ? 'medium' : '0px' }}
          >
            <Link to={HOME}>
              <Box
                style={{ position: 'absolute', top: '16px', left: '16px' }}
                alignSelf="start"
                round="full"
                elevation="small"
                pad="small"
                background="white"
              >
                <LinkPrevious size="24px" />
              </Box>
            </Link>
            <Box
              round={{
                corner: 'bottom',
                size: screenSize === 'small' ? '0px' : '8px',
              }}
              overflow={'hidden'}
            >
              <Image
                fit="contain"
                src={teamData?.coverImage?.urlOrig || 'cover_placeholder.png'}
              />
            </Box>
            <Box
              fill="horizontal"
              direction="row"
              // align="end"
              gap="medium"
              pad={{ horizontal: teamPhotoPaddingHorizontal, top: 'medium' }}
              justify="between"
            >
              <Box
                round="full"
                border={{
                  side: 'all',
                  color: 'white',
                  size: '2px',
                }}
                height={teamPhotoSize}
                width={teamPhotoSize}
                overflow={'hidden'}
                flex={{ shrink: 0 }}
                background={'light-1'}
                margin={{ top: `${marginTop}px` }}
              >
                <Image
                  fit="contain"
                  src={teamData?.image?.urlT150 || 'image_default.jpg'}
                />
              </Box>
              {screenSize === 'large' && (
                <DesktopTeamHeader
                  team={team}
                  isAdmin={isAdmin}
                  setShowQrModal={setShowQrModal}
                  screenSize={screenSize}
                />
              )}

              {screenSize !== 'large' && (
                <TeamActionButtons
                  isAdmin={isAdmin}
                  setShowQrModal={setShowQrModal}
                  screenSize={screenSize}
                />
              )}
            </Box>
            {screenSize !== 'large' && (
              <MobileTeamHeader
                team={team}
                isAdmin={isAdmin}
                trackEvent={trackLiveEvent}
              />
            )}
            <Sticky innerZ={9}>
              {(status) => (
                <Box background={'white'} fill="horizontal">
                  {status.status === Sticky.STATUS_FIXED && (
                    <Box
                      direction="row"
                      align={'center'}
                      justify={'between'}
                      pad={'large'}
                      fill="horizontal"
                    >
                      <Box
                        alignSelf="start"
                        onClick={() => {
                          history.push(HOME);
                        }}
                        round="full"
                        elevation="small"
                        pad="small"
                        background="white"
                      >
                        <LinkPrevious size="24px" />
                      </Box>
                      <Text weight="bold" size={'large'} alignSelf="center">
                        {team?.nameMed}
                      </Text>
                      <Box alignSelf="end" width={'24px'} />
                    </Box>
                  )}
                  <Tabs activeIndex={tabIndex} onActive={onActive}>
                    {tabs.map((tab) => (
                      <Tab title={tab} key={tab} />
                    ))}
                  </Tabs>
                </Box>
              )}
            </Sticky>
          </Box>
        </Box>
        <Box
          gridArea="header-right"
          background={'white'}
          border={{ side: 'bottom', color: 'light-1', size: '1px' }}
        ></Box>
        <Box gridArea="main-left" background={'light-5'}></Box>
        <Box gridArea="main" background={'light-5'}>
          {team && (
            <Box
              margin={{
                horizontal: screenSize === 'medium' ? 'medium' : '0px',
              }}
              pad={{ bottom: '88px' }}
            >
              {tabs[tabIndex] === 'Games' && (
                <Games team={team} isAdmin={isAdmin} />
              )}
              {tabs[tabIndex] === 'Roster' && (
                <Roster team={team} isAdmin={isAdmin} />
              )}

              {tabs[tabIndex] === 'HitScope' && (
                <TeamsHitScope
                  teamIdsString={teamId}
                  isNewLivestreamerExperience
                />
              )}
              {tabs[tabIndex] === 'Highlights' && !loading && (
                <Highlights team={team} isAdmin={isAdmin} />
              )}
            </Box>
          )}
        </Box>
        <Box gridArea="main-right" background={'light-5'}></Box>
      </ResponsiveGrid>
      {showQrModal && (
        <QrModal
          onClose={() => {
            setShowQrModal(false);
          }}
          team={team}
        />
      )}
      {showAccountInfoModal && (
        <Layer>
          <Box pad="medium">
            <AccountInfo
              currentStep={0}
              afterValidation={() => {
                setShowAccountInfoModal(false);
              }}
              inviteCode={fromInvite}
              isMissingName
            />
          </Box>
        </Layer>
      )}
    </Box>
  );
};

const withConditionalLayout = <P extends object>(
  Component: React.FC<P>
): React.FC<P> => {
  const ConditionalLayoutComponent: React.FC<P> = (props) => {
    const screenSize = useContext(ResponsiveContext);

    if (screenSize === 'small') {
      // Don't apply layout for small screens
      return <Component {...props} />;
    } else {
      // Apply layout for non-small screens
      const LayoutComponent = withLayout(Component);
      return <LayoutComponent {...props} />;
    }
  };

  return ConditionalLayoutComponent;
};

export default compose<Props, Omit<Props, keyof RouteComponentProps>>(
  withFirebase,
  withRouter,
  withConditionalLayout
)(TeamPage);
