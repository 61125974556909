// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import {
  Anchor,
  Avatar,
  Box,
  CheckBox,
  Form,
  Grommet,
  Heading,
  Layer,
  RadioButtonGroup,
  Text,
  TextInput,
} from 'grommet';
import React, { useState, useEffect, useContext } from 'react';
import Compressor from 'compressorjs';
import { withRouter, useParams } from 'react-router-dom';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import { User } from 'grommet-icons';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import withLayout from '../../helpers/withLayout';
import { UserContext } from '../../services/Session';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import SHDButton from '../../components/SHD/Button';
import PhoneNumberInput from '../../helpers/PhoneNumberInput';
import {
  FieldGradYear,
  FieldNameHandle,
  FieldPlayerPosition,
  FieldHandedness,
  FieldBBSBHandedness,
  FieldWeight,
  FieldGPA,
  FieldNameLocation,
  FieldCommitStatus,
  FieldCollege,
} from '../../helpers/FormFields';
import HelpTooltip from '../../helpers/HelpTooltip';
import FileUpload from '../../helpers/FileUpload';
import { withFirebase } from '../../services/Firebase';
import { BBSB_SPORTS, VOLLEYBALL } from '../../constants/strings';
import { shouldPromptForAccountInfo, useQuery } from '../../helpers/utils';
import { analytics } from '../../services/analytics';
import AccountInfo from '../Onboarding/AccountInfo';

const ClaimPlayer = observer((props) => {
  const userStore = useContext(UserContext);
  const { teamplayerid: teamPlayerId } = useParams();
  const query = useQuery();
  const key = query.get('key');
  const claimerPhoneNumber = query.get('phone');

  const [team, setTeam] = useState({});
  const [teamPlayer, setTeamPlayer] = useState({});
  const [existingClaimedPlayers, setExistingClaimedPlayers] = useState([]);
  const [selectedClaimedPlayer, setSelectedClaimedPlayer] =
    useState('newplayer');
  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [phoneNumber, setPhoneNumber] = useState(userStore.userPhone);
  const [playerValue, setPlayerValue] = useState({ nameHandle: '' });
  const [imageFile, setImageFile] = useState();
  const [checked, setChecked] = useState(false);
  const [isAtClaimLimit, toggleIsAtClaimLimit] = useState(false);
  const [isAwaitingVerification, setIsAwaitingVerification] = useState(true);

  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);

  const checkClaimRequest = () => {
    axios
      .post('/api/claim_request', {
        teamPlayerId,
        key,
        phoneNumber: userStore.userPhone,
        authUserId: props.firebase.auth.currentUser?.uid,
      })
      .then(({ data: { isClaimAllowed } }) => {
        if (isClaimAllowed) {
          setIsAwaitingVerification(false);
        } else {
          setIsAwaitingVerification(true);
          toggleLoading(false);
        }

        axios
          .get(`/api/claim_player/${teamPlayerId}/${key}`)
          .then(({ data }) => {
            const {
              team: respTeam,
              teamPlayer: respTeamPlayer,
              existingClaimedPlayers: respExistingClaimedPlayers,
              userPhoneNumber,
            } = data;

            setTeam(respTeam);
            setTeamPlayer(respTeamPlayer);

            const filteredNameFirst = respTeamPlayer.nameFirst
              .toLowerCase()
              .replace(/[^a-z-]/g, '');
            const filteredNameLast = respTeamPlayer.nameLast
              .toLowerCase()
              .replace(/[^a-z-]/g, '');
            const defaultHandle = `${filteredNameFirst}${filteredNameLast}${Math.floor(
              Math.random() * 1000
            ).toString()}`;

            setPlayerValue({ nameHandle: defaultHandle });
            setExistingClaimedPlayers(respExistingClaimedPlayers);
            setPhoneNumber(userPhoneNumber);
          })
          .catch((respError) => {
            Sentry.captureException(respError);
            if (respError.response) {
              if (respError.response.status === 409) {
                toggleIsAtClaimLimit(true);
              }
              setError(respError.response.data.message);
            } else {
              setError('Something went wrong. Please refresh and try again.');
            }
            toggleLoading(false);
            window.scrollTo(0, 0);
          });
      });
  };

  useEffect(() => {
    analytics.track('Claim Player Landing Page View', {
      key,
      phone: claimerPhoneNumber,
      campaign_id: query.get('campaign_id'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      autorun(() => {
        if (!userStore.authUser) {
          props.history.push(
            `/signin?claim_player=${teamPlayerId}&key=${key}&phone=${claimerPhoneNumber}`
          );
        } else {
          checkClaimRequest();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (teamPlayer._id) {
      toggleLoading(false);
    }

    if (teamPlayer.claimedPlayerId) {
      const newUser = {
        updateClaimedPlayers: true,
        claimedPlayerId: teamPlayer.claimedPlayerId,
      };
      const userId = userStore.authUser.claims.shd_user_id;

      axios.put(`/api/user/${userId}`, { newUser }).then(() => {
        localStorage.setItem('userRole', 1);
        props.history.push(`/${teamPlayer.claimedPlayerId}`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamPlayer]);

  useEffect(() => {
    const shouldPrompt = shouldPromptForAccountInfo(
      userStore.user?.nameFirst,
      userStore.user?.nameLast
    );
    if (shouldPrompt) {
      setShowAccountInfoModal(true);
    }
  }, [userStore, userStore.authUser, userStore.user]);

  const onValidPhone = (value) => {
    if (value) {
      setPhoneNumber(value);
      setPhoneError(null);
    } else {
      setPhoneNumber(null);
    }
  };

  const onErrorPhone = (respError) => {
    if (respError) {
      setPhoneError(true);
      setPhoneNumber(null);
    } else {
      setPhoneError(null);
    }
  };

  const claimPlayer = () => {
    toggleLoading(true);
    const body = { ...playerValue };
    delete body.nameHandle;
    axios
      .post(`/api/claim_player/${teamPlayerId}/n`, {
        claimedPlayerId:
          selectedClaimedPlayer === 'newplayer' ? '' : selectedClaimedPlayer,
        teamId: teamPlayer.DNOR_teamId,
        phoneNumber,
        handle:
          selectedClaimedPlayer === 'newplayer' ? playerValue.nameHandle : '',
        ...body,
      })
      .then(({ data }) => {
        const { handle, claimedPlayerId } = data;
        if (imageFile) {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              responseType: 'arraybuffer',
            },
          };
          return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-new
            new Compressor(imageFile, {
              maxWidth: 1920,

              success(result) {
                const formData = new FormData();

                // The third parameter is required for server
                formData.append('image', result, result.name);

                axios
                  .post(
                    `/api/player/${claimedPlayerId}/image`,
                    formData,
                    config
                  )
                  .then(() => {
                    resolve(handle);
                  })
                  .catch(reject);
              },
              error(err) {
                setError(err.message);
              },
            });
          });
        }

        return handle;
      })
      .then((handle) => {
        userStore.apiSetTeams().then(() => {
          toggleLoading(false);
          props.history.push(`/${handle}`);
        });
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        if (respError.response) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. Please refresh and try again.');
        }
        toggleLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const handleFile = (respImageFile, imageError) => {
    if (imageError) {
      setError(imageError);
      window.scrollTo(0, 0);
      return false;
    }
    setError(null);
    setImageFile(respImageFile);
    return true;
  };

  if (loading) {
    return <BasicLoader fullPage />;
  }

  if (teamPlayer.claimedPlayerId) {
    return (
      <Box justify="center" pad="medium" gap="medium">
        <Heading level={3} color="text-xweak" textAlign="center">
          This player has already been claimed.
        </Heading>
        <Heading level={4} color="text-xweak" textAlign="center">
          We are adding you as a player manager...
        </Heading>
        <BasicLoader />
      </Box>
    );
  }

  if (isAtClaimLimit) {
    return (
      <Box justify="center" direction="row" pad="medium">
        <Heading level={4} color="text-xweak" textAlign="center">
          {error}
        </Heading>
      </Box>
    );
  }

  const claimPlayerDiv = (
    <>
      {error && <Text color="status-critical">{error}</Text>}
      <Heading level={4} textAlign="center">
        {`${teamPlayer.nameFirst} ${teamPlayer.nameLast} #${teamPlayer.jerseyNum}`}
      </Heading>
      <Form
        value={playerValue}
        onChange={(nextValue) => setPlayerValue(nextValue)}
        onSubmit={claimPlayer}
      >
        <Box gap="large">
          {selectedClaimedPlayer === 'newplayer' ? (
            <FieldNameHandle label="Player handle" placeholder="williemays24" />
          ) : null}

          {existingClaimedPlayers.length ? (
            <Box gap="small" pad="small">
              <Text>Is this one of your existing players?</Text>
              <RadioButtonGroup
                name="radio"
                options={existingClaimedPlayers
                  .map((player) => {
                    const value = player._id;
                    const label = `${player.nameFirst} ${player.nameLast}`;
                    return { label, value };
                  })
                  .concat({
                    label: 'This is a new player',
                    value: 'newplayer',
                  })}
                value={selectedClaimedPlayer}
                onChange={(event) =>
                  setSelectedClaimedPlayer(event.target.value)
                }
              />
            </Box>
          ) : null}
          {!userStore.userPhone ? (
            <Box pad="small" gap="small">
              <Box direction="row" gap="small" align="center">
                <Text>Your phone number</Text>
                <HelpTooltip
                  content={
                    <Box background={'light-3'} pad="small">
                      <Text>
                        We send text messages for player notifications
                      </Text>
                    </Box>
                  }
                />
              </Box>
              <PhoneNumberInput
                value={phoneNumber}
                id="text-input"
                placeholder="Your number"
                onValid={onValidPhone}
                onError={onErrorPhone}
              />
              {phoneError && (
                <Text color="status-critical">
                  Please enter a valid phone number.
                </Text>
              )}
            </Box>
          ) : null}
          {selectedClaimedPlayer === 'newplayer' ? (
            <>
              <Heading level={5} margin={{ left: 'small' }}>
                Optional info
              </Heading>
              <Box
                direction="row"
                align="center"
                gap="small"
                justify="start"
                height={{ min: 'small' }}
              >
                {imageFile ? (
                  <Avatar src={URL.createObjectURL(imageFile)} size="large" />
                ) : (
                  <Avatar background="dark-1" size="large">
                    <User color="white" size="large" />
                  </Avatar>
                )}
                <FileUpload handleFile={handleFile}>
                  <Anchor
                    label={`${imageFile ? 'Change' : 'Add'} profile picture`}
                  />
                </FileUpload>
              </Box>
              <FieldNameLocation isTeam={false} />
              <FieldGradYear />
              <FieldPlayerPosition
                isPrimary
                sportType={team.attrib_sportType}
              />
              <FieldPlayerPosition
                isPrimary={false}
                sportType={team.attrib_sportType}
              />
              {BBSB_SPORTS.includes(team.attrib_sportType) && (
                <>
                  <FieldBBSBHandedness isBatting />
                  <FieldBBSBHandedness isBatting={false} />
                </>
              )}
              {team.attrib_sportType === VOLLEYBALL.display && (
                <FieldHandedness />
              )}
              {/* <FieldHeight /> */}
              <Box gap="xsmall" margin={{ vertical: 'xsmall' }}>
                <Text size="large" color="dark-3">
                  Height
                </Text>
                <Box direction="row" gap="xsmall" align="center">
                  <TextInput
                    autoComplete="nope"
                    id="height-feet-id"
                    placeholder="5"
                    onChange={(e) => {
                      setPlayerValue((prevValue) => {
                        let heightInches = '0';
                        if (
                          prevValue.height &&
                          prevValue.height.split("'").length > 1
                        ) {
                          heightInches = prevValue.height
                            .split("'")[1]
                            .replace('"', '');
                        }

                        const newValue = { ...prevValue };
                        let newHeight = '';
                        if (heightInches) {
                          newHeight = `${e.target.value}'${heightInches}"`;
                        }

                        newValue.height = newHeight;
                        return newValue;
                      });
                    }}
                    value={
                      playerValue.height ? playerValue.height.split("'")[0] : ''
                    }
                    plain
                  />
                  <Text color="text-weak">ft</Text>
                  <TextInput
                    autoComplete="nope"
                    id="height-inches-id"
                    placeholder="5"
                    onChange={(e) => {
                      setPlayerValue((prevValue) => {
                        let heightFeet = '5';
                        if (
                          prevValue.height &&
                          prevValue.height.split("'").length > 1
                        ) {
                          [heightFeet] = prevValue.height.split("'");
                        }

                        const newValue = { ...prevValue };
                        let newHeight = '';
                        if (heightFeet) {
                          newHeight = `${heightFeet}'${e.target.value}"`;
                        }

                        newValue.height = newHeight;
                        return newValue;
                      });
                    }}
                    value={
                      playerValue.height
                        ? playerValue.height.split("'")[1].replace('"', '')
                        : ''
                    }
                    plain
                  />
                  <Text color="text-weak">in</Text>
                </Box>
              </Box>
              <FieldWeight />
              <FieldCommitStatus />
              {playerValue.collegeCommitStatus === 10 ? <FieldCollege /> : null}
              <FieldGPA />
            </>
          ) : null}
          <Box pad="small" gap="small" direction="row">
            <Grommet
              theme={{
                checkBox: {
                  color: '#066d99',
                },
              }}
            >
              <CheckBox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
              />
            </Grommet>
            <Text size="small">
              I am this player or this player&apos;s guardian, and I am at least
              13 years old.
            </Text>
          </Box>
          <Box justify="between" fill="horizontal" direction="row" gap="small">
            <SHDButton
              size="large"
              label="Exit"
              href="https://sidelinehd.com"
            />
            <SHDButton
              size="large"
              label="Continue"
              primary
              type="submit"
              disabled={!(checked && phoneNumber)}
            />
          </Box>
        </Box>
      </Form>
    </>
  );

  const awitingVerification = (
    <Heading level={4} color="text-xweak" textAlign="center">
      {`Awaiting verification.
      The team admin needs to verify your identity before you can continue
      with claiming ${teamPlayer.nameFirst} ${teamPlayer.nameLast}.`}
    </Heading>
  );

  return (
    <Box pad="large" margin="0 auto" justify="center" gap="large">
      {isAwaitingVerification ? awitingVerification : claimPlayerDiv}
      {showAccountInfoModal && (
        <Layer pad="medium">
          <AccountInfo
            currentStep={0}
            afterValidation={() => {
              setShowAccountInfoModal(false);
            }}
            isMissingName
          />
          <Box height={'24px'} />
        </Layer>
      )}
    </Box>
  );
});

const ClaimPlayerPage = compose(
  withLayout,
  withRouter,
  withFirebase
)(ClaimPlayer);

export default ClaimPlayerPage;
